// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBeoSnk1FcGf9y2PEVi1I7VuB1m-qnXXes",
    authDomain: "saudi-design-festival.firebaseapp.com",
    projectId: "saudi-design-festival",
    storageBucket: "saudi-design-festival.appspot.com",
    messagingSenderId: "168808393474",
    appId: "1:168808393474:web:755c826f27f622eb0cb01b",
    measurementId: "G-J134VGY3BT"
  },
  reCaptchaSiteKey: "6Ldc9vwdAAAAABD76u4rJUDaLtMMtEhBIVL8eERf"

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
