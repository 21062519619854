import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase/app';

import { AngularFireFunctions } from '@angular/fire/functions';
@Injectable({
    providedIn: 'root'
})
export class ParticipationsCollection {
    public key = 'homepage';

    public pageData = null;

    constructor(private firestore: AngularFirestore, private functions: AngularFireFunctions) {
    }



    public get() {
        return this.firestore.collection("creatives-participations").valueChanges()
    }
    public save(data, reCaptchaToken) {
        return this.functions.httpsCallable('creativeParticipation')({ ...data, reCaptchaToken: reCaptchaToken }).pipe().toPromise()

        //  return this.firestore.collection("creatives-participations").add(data)
    }

    public getClients() {
        return this.firestore.collection("clients-participations").valueChanges()
    }
    public saveClientParticipation(data, reCaptchaToken) {
        return this.functions.httpsCallable('clientParticipation')({ ...data, reCaptchaToken: reCaptchaToken }).pipe().toPromise()
        //  return this.firestore.collection("clients-participations").add(data)
    }



    public incrementKeywords(key) {
        const increment = firestore.FieldValue.increment(1);
        const userLike = this.firestore.doc(`keywords/creatives`);
        let obj = {}
        key.map(
            k => {
                obj[k] = increment;
            }
        )
        obj['totalSubmissions'] = increment;

        return userLike.update(obj);
    }
    public getStatsCreatives() {
        return this.firestore.collection("keywords").doc('creatives').valueChanges();
    }


    public incrementClientKeywords(key) {
        const increment = firestore.FieldValue.increment(1);
        const userLike = this.firestore.doc(`keywords/clients`);
        let obj = {}
        key.map(
            k => {
                obj[k] = increment;
            }
        )
        obj['totalSubmissions'] = increment;

        return userLike.update(obj);
    }
    public getStatsClients() {
        return this.firestore.collection("keywords").doc('clients').valueChanges();
    }


    public joinUs(data, reCaptchaToken) {
        return this.functions.httpsCallable('join')({ ...data, reCaptchaToken: reCaptchaToken }).pipe().toPromise()
        //  return this.firestore.collection("join-us").add(data)
    }

}

