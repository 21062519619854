import { NgModule } from '@angular/core';
// import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
// import { MarkdownModule } from 'ngx-markdown';
import { DataModule } from './data/data.module';
//import { MarkdownModule } from 'ngx-markdown'
// import { environment } from '../../environments/environment';
// import { ApiService } from './api/api.service';
// import { PreloaderCheckService } from './preloader/preloader-check.service';
// import { PreloaderService } from './preloader/preloader.service';
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { environment } from '../../environments/environment';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaFormsModule } from "ng-recaptcha";
@NgModule({

  imports: [
    DataModule,
    //   AnalyticsModule,
    // MarkdownModule.forRoot(),
    // RecaptchaV3Module,

    //  MarkdownModule.forRoot(),

    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,

  ],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaSiteKey },
    //  ApiService,
    //  PreloaderCheckService, PreloaderService,

    //  { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaSiteKey },
  ],
})
export class ProvidersModule { }
