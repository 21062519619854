import { NgModule } from '@angular/core';
import { ParticipationsCollection } from './models/participations.collection';
@NgModule({
  providers: [
    ParticipationsCollection,
  ],
  imports: [
  ],
})
export class DataModule { }
